import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum CancelSKUActionTypes {
  CANCEL_SKU_ACTION = 'CANCEL_SKU_ACTION',
  CANCEL_SKU_ACTION_SUCCESS = 'CANCEL_SKU_ACTION_SUCCESS',
  CANCEL_SKU_ACTION_FAILURE = 'CANCEL_SKU_ACTION_FAILURE',
  CANCEL_SKU_ACTION_RESET = 'CANCEL_SKU_ACTION_RESET',
}

export interface ICancelSkuData {
  skuId: string;
  quantity: number;
  cancelAmount: number;
}

export interface ICancelReasons {
  cancellationReason: string;
  cancellationSubreason: string;
  cancellationRemarks: string;
}

export interface ICancelSKUPayload {
  customerId: number,
  totalCancellationAmount: number;
  skuList: Array<ICancelSkuData>;
  reasonDetails: ICancelReasons;
  emiInterestAmount?: number;
}

export interface ICancelSKUActionData {
  supplyOrderAliasId: string;
  payload: ICancelSKUPayload;
}

export const cancelSKU = createAction(CancelSKUActionTypes.CANCEL_SKU_ACTION)<ICancelSKUActionData>();
export const cancelSKUSuccess = createAction(CancelSKUActionTypes.CANCEL_SKU_ACTION_SUCCESS)();
export const cancelSKUFailure = createAction(CancelSKUActionTypes.CANCEL_SKU_ACTION_FAILURE)<IErrorActionData>();
export const cancelSKUReset = createAction(CancelSKUActionTypes.CANCEL_SKU_ACTION_RESET)();

export type CancelSKUAction = ActionType<typeof cancelSKU>;
type CancelSKUSuccessAction = ActionType<typeof cancelSKUSuccess>;
type CancelSKUFailureAction = ActionType<typeof cancelSKUFailure>;
type CancelSKUResetAction = ActionType<typeof cancelSKUReset>;

export type CancelSKUActions =
  | CancelSKUAction
  | CancelSKUSuccessAction
  | CancelSKUFailureAction
  | CancelSKUResetAction;

import { createAction, ActionType } from 'typesafe-actions';

import { IErrorActionData } from '../../../models/Error';

export enum CancelOrderActionTypes {
  CANCEL_ORDER_ACTION = 'CANCEL_ORDER_ACTION',
  CANCEL_ORDER_ACTION_SUCCESS = 'CANCEL_ORDER_ACTION_SUCCESS',
  CANCEL_ORDER_ACTION_FAILURE = 'CANCEL_ORDER_ACTION_FAILURE',
  CANCEL_ORDER_ACTION_RESET = 'CANCEL_ORDER_ACTION_RESET',
}

export interface ICancelOrderPayload {
  cancellationReason: string;
  cancellationSubreason: string;
  cancellationRemark: string;
  emiInterestAmount?: number;
}

export interface ICancelOrderActionData {
  orderReferenceId: string;
  payload: ICancelOrderPayload;
}

export const cancelOrder = createAction(CancelOrderActionTypes.CANCEL_ORDER_ACTION)<ICancelOrderActionData>();
export const cancelOrderSuccess = createAction(CancelOrderActionTypes.CANCEL_ORDER_ACTION_SUCCESS)();
export const cancelOrderFailure = createAction(CancelOrderActionTypes.CANCEL_ORDER_ACTION_FAILURE)<IErrorActionData>();
export const cancelOrderReset = createAction(CancelOrderActionTypes.CANCEL_ORDER_ACTION_RESET)();

export type CancelOrderAction = ActionType<typeof cancelOrder>;
type CancelOrderSuccessAction = ActionType<typeof cancelOrderSuccess>;
type CancelOrderFailureAction = ActionType<typeof cancelOrderFailure>;
type CancelOrderResetAction = ActionType<typeof cancelOrderReset>;

export type CancelOrderActions =
  | CancelOrderAction
  | CancelOrderSuccessAction
  | CancelOrderFailureAction
  | CancelOrderResetAction;
